import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: '404',
    meta: { title: '404', layout: 'main' },
    component: () => import('../views/Default'),
  },
  {
    path: '/sendsay',
    name: 'Main',
    meta: { title: 'Dashboard', layout: 'main' },
    component: () => import('../views/Main.vue'),
  },
  {
    path: '/owner',
    name: 'Owner',
    meta: { title: 'Dashboard', layout: 'main' },
    component: () => import('../views/Owner.vue'),
  }
];

const router = new VueRouter({
  //mode: 'history',
  //linkActiveClass: 'active',
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  // todo добавить обработчик напрямую компоненту?
  if (to.name === 'Main') {
    await Store.dispatch('init', {to, from});
  }
  if (to.name === 'Owner') {
    await Store.dispatch('initOwner', {to, from});
  }
  next();
});

export default router;
