import Axios from 'axios'

const axios = Axios.create();

axios.interceptors.request.use(
  (config) => {
    /*let token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`;
    }*/
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
