<template>
  <div class="stage">
    <el-skeleton v-if="loading" />
    <div v-else class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "main-layout",
  components: {
  },
  computed: {
    ...mapGetters({
      loading: 'getLoading'
    })
  }
};
</script>