import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins';

Vue.config.productionTip = false;

document.addEventListener('touchmove', function(event) {
  event = event.originalEvent || event;
  if (event.scale !== 1) {
    event.preventDefault();
  }
}, false);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
