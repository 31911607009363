import Vue from 'vue';

const baseUrl = process.env.VUE_APP_ENV_BASE_URL + '/api';

const getRequest = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    Vue.axios.get(baseUrl + url, { params })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data)
        }
        else {
          reject(response)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
};

const postRequest = (url, payload) => {
  return new Promise((resolve, reject) => {
    Vue.axios.post(baseUrl + url, payload)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data)
        }
        else {
          reject(response)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
};

export const getInitial = () => getRequest('/initial');
export const saveSettings = (payload) => postRequest('/settings', payload);

export const getOwnerInitial = () => getRequest('/owner/initial');
export const saveOwnerSettings = (payload) => postRequest('/owner/settings', payload);
