import Vue from 'vue';
import Vuex from 'vuex';
import {getInitial, getOwnerInitial} from "../services/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    inited: false,
    settings: {},
    events: {},
    ownerSettings: {},
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getSettings(state) {
      return state.settings;
    },
    getEvents(state) {
      return state.events;
    },
    getOwnerSettings(state) {
      return state.ownerSettings;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    inited(state) {
      state.inited = true;
    },
    setSettings(state, payload) {
      state.settings = payload;
    },
    setEvents(state, payload) {
      state.events = payload;
    },
    setOwnerSettings(state, payload) {
      state.ownerSettings = payload;
    },
  },
  actions: {
    async init({ commit, dispatch }) {
      try {
        commit('setLoading', true);
        await dispatch('getInitial');
        commit('inited');
        commit('setLoading', false);
      } catch (e) {
        commit('setLoading', false);
      }
    },
    async getInitial({ commit }) {
      try {
        commit('setLoading', true);
        const { settings, events } = await getInitial();
        commit('setSettings', settings);
        commit('setEvents', events);
        commit('setLoading', false);
      } catch (e) {
        commit('setLoading', false);
        console.log(e)
      }
    },
    async initOwner({ commit, dispatch }) {
      try {
        commit('setLoading', true);
        await dispatch('getOwnerInitial');
        commit('inited');
        commit('setLoading', false);
      } catch (e) {
        commit('setLoading', false);
      }
    },
    async getOwnerInitial({ commit }) {
      try {
        commit('setLoading', true);
        const { settings, events } = await getOwnerInitial();
        commit('setOwnerSettings', settings);
        commit('setEvents', events);
        commit('setLoading', false);
      } catch (e) {
        commit('setLoading', false);
        console.log(e)
      }
    },
  },
});
