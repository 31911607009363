import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from './axios';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

//import { Tabs, Checkbox, CheckboxGroup, Button } from 'element-ui';
/*Vue.component(Tabs.name, Tabs);
Vue.component(Checkbox.name, Checkbox);
Vue.component(CheckboxGroup.name, CheckboxGroup);
Vue.component(Button.name, Button);*/

Vue.use(ElementUI);
Vue.use(VueAxios, axios);
